import * as React from 'react'
import Layout from '../components/layout'

const RecordsPage = () => (
  <Layout withoutFooter>
    <div className='page-header'>
      <div className='container'>
        <div className='sixteen columns'>
          <h1 className='page-title'>Kontaktujte nás</h1>
        </div>
      </div>
    </div>

    <div className='container main'>
      <div className='sixteen columns'>
        <div className='row'>
          <div className='gmap-wrapper'>
            <iframe
              width='100%'
              height='350'
              frameBorder='0'
              scrolling='no'
              marginHeight='0'
              marginWidth='0'
              src='https://maps.google.cz/maps?f=q&amp;source=s_q&amp;hl=cs&amp;geocode=&amp;q=Okru%C5%BEn%C3%AD+1756%2F13,+%C4%8Cesk%C3%BD+T%C4%9B%C5%A1%C3%ADn&amp;aq=0&amp;oq=okru%C5%BEn%C3%AD+1756+%2F+13&amp;sll=49.733471,18.617056&amp;sspn=0.00629,0.016512&amp;t=m&amp;g=okru%C5%BEn%C3%AD+1756+%2F+13&amp;br/current=5,0,0&amp;ie=UTF8&amp;hq=&amp;hnear=Okru%C5%BEn%C3%AD+1756%2F13,+737+01+%C4%8Cesk%C3%BD+T%C4%9B%C5%A1%C3%ADn&amp;ll=49.733471,18.617056&amp;spn=0.00629,0.016512&amp;z=14&amp;output=embed'
            ></iframe>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='sixteen columns'>
          <h3 className='title'>
            <span>Bytové družstvo</span>
          </h3>
          <ul>
            <li>předseda představenstva - Ing. Bohuslav Klunda, Okružní 1756/13, Český Těšín</li>
            <li>člen představenstva - p. Soňa Cywková, Okružní 1756/13, Český Těšín</li>
            <li>člen představenstva - Ing. Josef Gaš, Okružní 1755/11, Český Těšín</li>
            <li>předseda kontrolní komise - Ing. Jan Žabka, Okružní 1755/11, Český Těšín</li>
          </ul>

          <p>
            Správu bytového družstva vykonává p. Jana Košturová, Kostelní 925/3, Český Těšín, tel. 608 321 346, 558 741
            467
          </p>
        </div>
      </div>

      <div className='row'>
        <div className='one-third column'>
          <h3 className='title'>
            <span>Kontaktní údaje</span>
          </h3>

          <ul className='label-list'>
            <li>
              <span className='label'>
                <i className='icon-home'></i> Adresa
              </span>
              <span>
                Okružní 1756 / 13
                <br />
                737 01 Český Těšín
              </span>
            </li>
          </ul>
        </div>
        <div className='one-third column'>
          <h3 className='title'>
            <span>Havarijní služby</span>
          </h3>
          <p>
            <strong>ČEZ, a.s.</strong>
            <br />
            zákaznická linka: 840 840 840
            <br />
            poruchy: 840 850 860
            <br />
            <br />
            <strong>Severomoravské vodovody a kanalizace, a.s.</strong>
            <br />
            zákaznická linka: 840 111 123
            <br />
            poruchy: 840 111 125
            <br />
            <br />
            <strong>Severomoravská plynárenská, a.s.</strong>
            <br />
            zákaznická linka: 840 111 115
            <br />
            poruchy: 1239
            <br />
          </p>
        </div>
        <div className='one-third column'>
          <h3 className='title'>
            <span>Opravy</span>
          </h3>
          <p>
            <strong>Plyn - Gas systém</strong>
            <br />
            tel. 777 178 817
            <br />
            <br />
            <strong>Elektřina - p. Niemiec</strong>
            <br />
            tel. 737 070 030
            <br />
            <br />
            <strong>Voda, topení - p. Sebera</strong>
            <br />
            tel. 777 047 519
            <br />
            <br />
            <strong>Plyn. sporáky - p. Portysz</strong>
            <br />
            tel. 732 287 408
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default RecordsPage
